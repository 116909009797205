import React from 'react';
import '../css/About.css'
import JessPic from '../pictures/Jess.jpg'

const About = () => {
    return (
        <div className='about' id='about'>
            <h3 className='about__h'>ABOUT</h3>
            <br></br>
            <img className='about__img' src={JessPic} alt="Jessica Morse" ></img>
            <p className='about__p'>Jessica is a Denver native who began her humble journey into holistic wellness and natural living in 2011 when she returned to university for her undergrad. She has since made a personal mission of living a conscious and embodied life and enabling her loved ones and community to do the same by serving as a resource.</p>
            <p className='about__p'>This has taken the form of being an herbalist, farmer and gardener, supplement specialist, mentor, teacher and even a botanical barista. Not to mention serving as a personal in-house doctor to family, friends and roommates, with a well-stocked apothecary, and the best gift-giver of handmade botanical bath and body goodies, and the best medicine of all: home cookin’.</p>
            <p className='about__p'>Some of Jessica’s passions are ecstatic dance, roller skating, the great American novel and the great American road trip. She incorporates her knowledge and studies of Ayurveda, archetypes, astrology, vibrational, and food medicines into her wellness exchanges. She enjoys finding creative ways to combine food and medicine and has a deep desire to continue to mentor girls and young women in the community, as well as love on and care for all of the plants and animals she comes into contact with.</p>
        </div>
    );
}

export default About;
