import React from 'react';
import '../css/Contact.css'

const Contact = () => {
    return (
        <div className='contact' id='contact'>
            <h3 className='contact__h'>CONTACT ME</h3>
            <p className='contact__p'>Reach out to me, I'd love to get in touch!</p>
            <form action="https://send.pageclip.co/yHUqZcUWJP4Aej9S8TuyZaNi6uAoOBlz" className="pageclip-form" method="post">
                <div>
                    <input className="pageclip__input" type="text" name="name" placeholder="first name" />
                    <input className="pageclip__input" type="email" name="email" placeholder="email" />
                    <textarea className="pageclip__message" type="text" name="message" placeholder="your message"></textarea>
                    <button type="submit" className="pageclip-form__submit">
                        <span>Send</span>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default Contact;

