import React from 'react';
import '../css/Nav.css'
import { Link } from 'react-scroll'

const Nav = () => {
    return (
        <nav className='nav'>
            <Link 
                className='nav__link'
                activeClass='active' 
                to='main' 
                spy={true} 
                smooth={true} 
                duration={500}
            >Main</Link>
            <Link 
                className='nav__link'
                activeClass='active' 
                to='about' 
                spy={true} 
                smooth={true} 
                duration={500}
            >About</Link>
            <Link 
                className='nav__link'
                activeClass='active' 
                to='contact' 
                spy={true} 
                smooth={true} 
                duration={500}
            >Contact</Link>   
            <a 
                className='nav__resume'
                target='_blank'
                rel='noreferrer'
                href='https://docs.google.com/document/d/1SCwkdnubhZiPcfXbN-aSzPXMF-iSQjzHPeauK-V9gwk/edit'
            >Resume</a>  
            <br></br>
            <img
                className='nav__up-arrow'
                alt='to top' 
                onClick={()=>{window.scrollTo({top:0, left:0, behavior:'smooth'})}}
                src='https://img.icons8.com/android/2x/ffffff/up.png'></img>           
        </nav>
    );
}

export default Nav;
