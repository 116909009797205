import '../css/Main.css'
import React from 'react';
import { Link } from 'react-scroll'


const Main = () => {
    return (
        <div className='main' id='main'>
            <h1 className='main__name'>JESSICA MORSE</h1>
            <br></br>
            <h3 className='main__intro'>Colorado-based wellness guide specializing in herbalism, nutrition and archetypal medicine. <br></br>Pursuing and creating opportunities for education, community, and service in the wellness arena.</h3>
            <h3 className='main__h'>"And there was a new voice, which you slowly recognized as your own, that kept you company as you strode deeper and deeper into the world."<br></br>--Mary Oliver, The Journey</h3>
            <Link 
                className='main__contact__btn'
                to='contact' 
                spy={true} 
                smooth={true} 
                duration={500}
            >Contact</Link>
        </div>
    );
}

export default Main;
