import { Parallax } from 'react-parallax';
import React from 'react';
import '../css/App.css';
import Main from './Main';
import About from './About';
import Contact from './Contact';
import Nav from './Nav';
import green from '../pictures/green-claudel-rheault.jpg'
import pink from '../pictures/pink-yang-shuo.jpg'
import blue from '../pictures/blue-alex-shutin.jpg'

const mainImg = green
const aboutImg = blue
const contactImg = pink

function App() {

  return (
    <div className="app">

      <div className="nav__div">
        <Nav />
      </div>

      <div className="content__div">
        <Parallax bgImage={mainImg} strength={500}>
          <Main />      
        </Parallax>

        <Parallax bgImage={aboutImg} strength={500}>
          <About />
        </Parallax>

        <Parallax bgImage={contactImg} strength={500}>
          <Contact />
        </Parallax>
      </div>
      
    </div>
  );
}

export default App;
